import React, { useState, useEffect } from "react";
import { login, pincodeApi, registerNewSeller } from "../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toasty from "../Toastify";
import { useNavigate } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
import ImageItem from "../ImageItem";

const validationSchema = Yup.object().shape({
  shopCode: Yup.string().required("Please Enter Shop Code"),
  address: Yup.string().required("Please Enter Address"),
  outletName: Yup.string().required("Please Enter Outlet Name"),
  sector: Yup.string().required("Please Enter Sector"), //oo
  pincode: Yup.string()
    .required("Please Enter Pincode")
    .matches(/^[1-9][0-9]{5}$/, "Please Enter a Valid Pincode"),
  mobile: Yup.string()
    .required("Please Enter Mobile Number"),
    // .matches(/^[6-9]\d{9}$/, "Please Enter Valid Mobile Number"),
  panNo: Yup.string().required("PAN Number is required").matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Please Enter Valid Pan No. Ex: ABCTY1234D"),
  gstNo: Yup.string().required("GST is required").matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, "Please Enter Valid Gst. Ex: 22AAAAA0000A1Z5 (State Code + PAN No. + Entity No. + Z + one digit)"),
  gstProof: Yup.string().required("Please Upload GST Proof"),
  panProof: Yup.string().required("Please Uplaod Pan Card Image"),
  fromTime: Yup.string().required("Please Enter Stores's Opening Time"),
  toTime: Yup.string().required("Please Enter Stores's Closing Time"),
  min_order: Yup.string().required("Please Enter Minimum Number of Order"),
  lat: Yup.string().required("Please Enter Latitude"),
  lng: Yup.string().required("Please Enter Longitude"),
  legalDeclaration: Yup.boolean()
    .test(
      "is-true",
      "Please accept the legal declaration to proceed.",
      (value) => value === true
    ) // Custom test for boolean field
    .required(),
    // termsAndCondition: Yup.boolean()
    // .test(
    //   "is-true",
    //   "Please accept the Terms & Condition to proceed.",
    //   (value) => value === true
    // ) // Custom test for boolean field
    // .required(),
});

const initialState = {
  shopCode: "",
  outletName: "",
  address: "",
  sector: "",
  pincode: "",
  city: "",
  state: "",
  mobile: "",
  gstNo: "",
  panNo: "",
  gstProof: "",
  panProof: "",
  serviceAbilityRadius: "",
  shippingCharges: "",
  minOrderValue: "",
  timeToShip: "", //
  deliveryTat: "",
  lat: "",
  lng: "",
  fromTime: "",
  toTime: "",
  min_order: "",
  legalDeclaration: false,
  termsAndCondition: false,
  delTatType: "H",
  timeToShipType: "H",
  deliveryType:"Yes"
};

function Form({ setOpen, isNew, onToastyData, setOpenTandC, openTandC }) {
  const navigation = useNavigate();
  // const [pos, setPos] = useState(false);
  const [isValidPincode, setIsValidPincode] = useState("");
  const ToastyTime = 1000;
  const [showToasty, setShowToasty] = useState(false);
  const [toastyMessaga, setToastyMessaga] = useState("");
  const [toastifyStatus, setToastifyStatus] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [myObject, setMyObject] = useState({});
  const [imageArray, setImageArray] = useState([]);
  const [formDetails, setFormDetails] = useState();
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
  });
  const uidDirectLogin = localStorage.getItem("uid") ?? "";
  const mobileNo = localStorage.getItem("mobile") ?? "";
  const loginType = localStorage.getItem("login_type") ?? "";

  const appGeoLocation = () => {
    setSpinner(true);
    try {
      window.flutter_inappwebview
        .callHandler("fetchGeolocationCoords")
        .then((res) => {
          if (res) {
            const [lat, lng] = res.split(",");
            formik.setFieldValue("lat", lat);
            formik.setFieldValue("lng", lng);
            setSpinner(false);
          }
        });
    } catch {
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const { latitude } = position.coords;
            const { longitude } = position.coords;
            formik.setFieldValue("lat", latitude);
            formik.setFieldValue("lng", longitude);
            setSpinner(false);
          },
          function (error) {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }
  };
  const numberPattern = /^[0-9]+(\.[0-9]+)?$/;
  const imageUploadChange = (data) => {
    const updatedObject = { ...myObject, [data.id]: data.file };
    setMyObject(updatedObject);
    setImageArray(Object.values(updatedObject));
    formik.setFieldValue(data?.name, "yes")
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (
      [
        "pincode",
        "mobile",
        "shippingCharges",
        "min_order",
        "timeToShip",
        "deliveryTat",
        "minOrderValue",
        "serviceAbilityRadius",
        "lat",
        "lng",
      ].includes(name)
    ) {
      if (numberPattern.test(value) || value === "") {
        formik.setFieldValue(`${name}`, value);
      }
    } else {
      value = ["gstNo", "panNo"].includes(name) ? value.toUpperCase() : value;
      formik.setFieldValue(`${name}`, value);
    }
  };

  const handleCheckBoxChange = (e) => {
    formik.setFieldValue(`${e.target.name}`, e.target.checked);
  };

  const { pincode } = formik?.values;


  useEffect(() => {
    if (pincode?.length === 6) {
      (async () => {
        const result = await pincodeApi(pincode);
        setIsValidPincode(result[0]?.Status);
        if (result[0]?.Status === "Success") {
          formik.setFieldValue(`city`, result[0]?.PostOffice[0]?.District);
          formik.setFieldValue(`state`, result[0]?.PostOffice[0]?.State);
        }
      })();
    } else {
      setIsValidPincode("");
      formik.setFieldValue(`city`, "");
      formik.setFieldValue(`state`, "");
    }
    formik.setFieldValue(`shopCode`, uidDirectLogin);
    formik.setFieldValue(`mobile`, mobileNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pincode]);

  const handleSubmitForm = async (e) => {
    const { values } = formik;
    const formData = new FormData();
    formData.append("uid", values?.shopCode);
    formData.append("address", values?.address);
    formData.append("sector", values?.sector);
    formData.append("pincode", values?.pincode);
    formData.append("city", values?.city);
    formData.append("state", values?.state);
    formData.append("mobile", values?.mobile);
    formData.append("gst", values?.gstNo);
    formData.append("pan", values?.panNo);
    formData.append("lat", values?.lat);
    formData.append("lng", values?.lng);
    formData.append("outlet_name", values?.outletName);
    formData.append("from_time", values?.fromTime.replace(":", ""));
    formData.append("to_time", values?.toTime.replace(":", ""));
    formData.append("min_order", values?.min_order);
    formData.append("shipping_charges", values?.shippingCharges);
    formData.append("time_to_ship", values?.timeToShipType === "H" ? `PT${values?.timeToShip}H` : `P${values?.timeToShip}D`);
    formData.append("delivery_tat", values?.delTatType === "H" ? `PT${values?.deliveryTat}H` : `P${values?.deliveryTat}D`);
    formData.append("min_order_value", values?.minOrderValue)
    formData.append("delivery_type", values?.deliveryType)
    formData.append("source", loginType === "app" ? "unnati" : "ttt")
    imageArray.forEach((image) => {
      formData.append("proof_docs", image);
    });
    if (formik?.isValid && formik?.dirty && isValidPincode === "Success") {
      setFormDetails(formData);
      setOpenTandC({open: true, submit: false})
    } else {
      setToastifyStatus("warning");
      setToastyMessaga("Fill the Form Carefully");
      setShowToasty(true);
      setTimeout(() => {
        setShowToasty(false);
      }, ToastyTime + 500);
    }
  };

  useEffect(()=>{
    if (openTandC?.submit){
    (async()=>{
      const result = await registerNewSeller(formDetails);
      if (result?.success === true) {
        setToastifyStatus("success");
        localStorage.setItem("BankMobile", formik?.values?.mobile)
        setToastyMessaga(result?.message);
        formik?.handleReset();
        setTimeout(() => {
          localStorage.removeItem("uid");
          localStorage.removeItem("mobile");
          loginThroughNewlyRegistered(result);
        }, 1250);
      } else {
        setToastifyStatus("warning");
        setToastyMessaga(localStorage?.getItem("ApiError"));
        localStorage.removeItem("ApiError");
      }
      setShowToasty(true);
      setTimeout(() => {
        setShowToasty(false);
      }, ToastyTime + 500);
    })()
     
      
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTandC])

  const loginThroughNewlyRegistered = async (data) => {
    const payload = {
      userType: "seller",
      email: "",
      password: "",
      uniqueiD: data?.uuid,
    };
    try {
      const result = await login(payload);
      if (result) {
        // localStorage.setItem("login_type", "login");
        localStorage.setItem("userType", result.userType);
        localStorage.setItem(
          "outlet_name",
          result.userType === "seller"
            ? result.user_details[0].outlet_name
            : "Admin"
        );
        localStorage.setItem("token", result.token);
        navigation("/bank-details");
        onToastyData({
          isToast: true,
          success: "success",
          message: "Logged In Successfully",
        });
      } else {
        onToastyData({
          isToast: true,
          success: "warning",
          message: "Something Went Wrong",
        });
      }
      // Handle the result as needed
    } catch (error) {
      console.error("Error during login:", error);
      // Handle the error
    }
  };

  return (
    <div className="form mt-3 mx-2">
      <form action="/register" method="post" onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-5">
          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              value={formik?.values?.shopCode}
              name="shopCode"
              readOnly={uidDirectLogin === "" ? false : true}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">UID</label>
            {formik?.errors?.shopCode && formik?.touched?.shopCode && (
              <div className="validation-schema-error">
                {formik?.errors?.shopCode}
              </div>
            )}
          </div>

          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="address"
              value={formik?.values?.address}
              disabled={!isNew}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">Address</label>
            {formik?.errors?.address && formik?.touched?.address && (
              <div className="validation-schema-error">
                {formik?.errors?.address}
              </div>
            )}
          </div>

          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              value={formik?.values?.sector}
              name="sector"
              onChange={handleInputChange}
            />
            <label className="did-floating-label">Sector/Locality</label>
            {formik?.errors?.sector && formik?.touched?.sector && (
              <div className="validation-schema-error">
                {formik?.errors?.sector}
              </div>
            )}
          </div>

          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              value={formik?.values?.outletName}
              name="outletName"
              onChange={handleInputChange}
            />
            <label className="did-floating-label">Outlet name</label>
            {formik?.errors?.outletName && formik?.touched?.outletName && (
              <div className="validation-schema-error">
                {formik?.errors?.outletName}
              </div>
            )}
          </div>

          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="pincode"
              inputMode="numeric"
              value={formik?.values?.pincode}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">Pincode</label>
            {formik?.errors?.pincode && formik?.touched?.pincode && (
              <div className="validation-schema-error">
                {formik?.errors?.pincode}
              </div>
            )}
            {isValidPincode === "Success" && (
              <div
                style={{
                  color: "green",
                  fontSize: "13px",
                  margin: "1px 0 0 4px",
                }}
              >
                &#10004; PinCode Verified
              </div>
            )}
            {isValidPincode === "Error" && (
              <div
                style={{
                  color: "red",
                  fontSize: "13px",
                  margin: "1px 0 0 4px",
                }}
              >
                Invalid Pincode, Please Re-Check
              </div>
            )}
          </div>

          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="city"
              value={formik?.values?.city}
              // onChange={handleInputChange}
              readOnly
            />
            <label className="did-floating-label">City</label>
          </div>

          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="state"
              value={formik?.values?.state}
              // onChange={handleInputChange}
              readOnly
            />
            <label className="did-floating-label">State</label>
          </div>

          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              inputMode="numeric"
              name="mobile"
              readOnly={mobileNo === "" ? false : true}
              value={formik?.values?.mobile}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">Mobile</label>
            {formik?.errors?.mobile && formik?.touched?.mobile && (
              <div className="validation-schema-error">
                {formik?.errors?.mobile}
              </div>
            )}
          </div>
          {/* 
          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="watsappNo"
              // value={formik?.values?.shopCode}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">WhatsApp No</label>
            
          </div> */}
          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="panNo"
              value={formik?.values?.panNo}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">PAN No</label>
            {formik?.errors?.panNo && formik?.touched?.panNo && (
              <div className="validation-schema-error">
                {formik?.errors?.panNo}
              </div>
            )}
            <ImageItem
              key={1}
              defaultText={`Upload PAN *`}
              overlayText={`PAN *`}
              name="panProof"
              id={1}
              onChange={imageUploadChange}
            />
            {formik?.errors?.panProof && formik?.touched?.panProof && (
              <div className="validation-schema-error">
                {formik?.errors?.panProof}
              </div>
            )}
          </div>

          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="gstNo"
              value={formik?.values?.gstNo}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">GST No</label>
            {formik?.errors?.gstNo && formik?.touched?.gstNo && (
              <div className="validation-schema-error">
                {formik?.errors?.gstNo}
              </div>
            )}
            <ImageItem
              key={2}
              defaultText={`Upload GST Proof *`}
              overlayText={`GST`}
              name="gstProof"
              id={2}
              onChange={imageUploadChange}
            />
             {formik?.errors?.gstProof && formik?.touched?.gstProof && (
              <div className="validation-schema-error">
                {formik?.errors?.gstProof}
              </div>
            )}
          </div>
          <div className="did-floating-label-content w-100 flex">
            <div style={{ width: "50%" }}>
              <input
                className="did-floating-input"
                type="text"
                placeholder=""
                inputMode="numeric"
                name="timeToShip"
                value={formik?.values?.timeToShip}
                onChange={handleInputChange}
              />
              <label className="did-floating-label">Time To Ship</label>
              {formik?.errors?.timeToShip && formik?.touched?.timeToShip && (
                <div className="validation-schema-error">
                  {formik?.errors?.timeToShip}
                </div>
              )}
            </div>
            <div
              className="did-floating-label-content w-100"
              style={{ width: "50%", margin: "0" }}
            >
              <select
                name="timeToShipType"
                onChange={handleInputChange}
                className="did-floating-input"
              >
                <option key={0} value="H" className="did-floating-label">
                  Hours
                </option>
                <option key={1} value="D" className="did-floating-label">
                  Days
                </option>
              </select>
            </div>
          </div>
          <div className="did-floating-label-content w-100 flex">
            <div style={{ width: "50%" }}>
              <input
                className="did-floating-input"
                type="text"
                placeholder=""
                name="deliveryTat"
                inputMode="numeric"
                value={formik?.values?.deliveryTat}
                onChange={handleInputChange}
              />
              <label className="did-floating-label">Delivery TAT</label>
              {formik?.errors?.deliveryTat && formik?.touched?.deliveryTat && (
                <div className="validation-schema-error">
                  {formik?.errors?.deliveryTat}
                </div>
              )}
            </div>
            <div
              className="did-floating-label-content w-100"
              style={{ width: "50%", margin: "0" }}
            >
              <select
                name="delTatType"
                onChange={handleInputChange}
                className="did-floating-input"
              >
                <option key={0} value="H" className="did-floating-label">
                  Hours
                </option>
                <option key={1} value="D" className="did-floating-label">
                  Days
                </option>
              </select>
            </div>
          </div>
          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              inputMode="numeric"
              name="shippingCharges"
              value={formik?.values?.shippingCharges}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">Shipping Charges</label>
            {formik?.errors?.shippingCharges &&
              formik?.touched?.shippingCharges && (
                <div className="validation-schema-error">
                  {formik?.errors?.shippingCharges}
                </div>
              )}
          </div>
          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="minOrderValue"
              inputMode="numeric"
              value={formik?.values?.minOrderValue}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">
              Minimum Order For Free Shipping
            </label>
            {formik?.errors?.minOrderValue &&
              formik?.touched?.minOrderValue && (
                <div className="validation-schema-error">
                  {formik?.errors?.minOrderValue}
                </div>
              )}
          </div>
          <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="serviceAbilityRadius"
              inputMode="numeric"
              value={formik?.values?.serviceAbilityRadius}
              onChange={handleInputChange}
            />
            <label className="did-floating-label">
              Serviceability Radius (KM)
            </label>
            {formik?.errors?.serviceAbilityRadius &&
              formik?.touched?.serviceAbilityRadius && (
                <div className="validation-schema-error">
                  {formik?.errors?.serviceAbilityRadius}
                </div>
              )}
          </div>

          

          {/* <div className="did-floating-label-content w-100">
            <input
              className="did-floating-input"
              type="text"
              placeholder=""
              name="upi"
            />
            <label className="did-floating-label">UPI ID</label>
            
          </div> */}
        </div>
        <div className="mt-1 mb-1">
                <span style={{ color: "grey" }}>Self Delivery</span>
                <div className="switch-field mt-2">
                  <input
                    type="radio"
                    id="returnable-yes"
                    name="returnable-switch"
                    defaultChecked={true}
                    onChange={(e) => {
                      // setReturnable(e.target.checked);
                      formik.setFieldValue("deliveryType", "Yes");
                    }}
                  />
                  <label htmlFor="returnable-yes">Yes</label>
                  <input
                    type="radio"
                    id="returnable-no"
                    name="returnable-switch"
                    defaultChecked={false}
                    onChange={(e) => {
                      // setReturnable(!e.target.checked);
                      formik.setFieldValue("deliveryType", "No");
                    }}
                  />
                  <label htmlFor="returnable-no">No</label>
                </div>
              </div>

        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <ImageItem
            key={"0"}
            defaultText={"Upload Gst Certificate"}
            overlayText={`GST Image`}
            id={0}
          />

          <ImageItem
            key={"1"}
            defaultText={"Upload Pan Copy"}
            overlayText={"PAN Image"}
            id={1}
          />
        </div> */}

        {/* <div className="mt-5">
          <span style={{ color: "grey" }}>Delivery Readiness (Yes/No)</span>
          <div className="switch-field mt-2">
            <input
              type="radio"
              id="delivery-yes"
              name="delivery-switch"
              defaultChecked={true}
            />
            <label for="delivery-yes">Yes</label>
            <input
              type="radio"
              id="delivery-no"
              name="delivery-switch"
              defaultChecked={false}
            />
            <label for="delivery-no">No</label>
          </div>
        </div> */}

        <div className="mt-5">
          <span style={{ color: "grey" }}>ONDC operating time</span>

          <div className="pl-2.5 mt-5">
            <div className="did-floating-label-content w-100">
              <input
                className="did-floating-input"
                type="time"
                name="fromTime"
                value={formik?.values?.fromTime}
                onChange={handleInputChange}
              />
              <label className="did-floating-label">From</label>
              {formik?.errors?.fromTime && formik?.touched?.fromTime && (
                <div className="validation-schema-error">
                  {formik?.errors?.fromTime}
                </div>
              )}
            </div>

            <div className="did-floating-label-content w-100 mt-2">
              <input
                className="did-floating-input"
                type="time"
                name="toTime"
                value={formik?.values?.toTime}
                onChange={handleInputChange}
              />
              <label className="did-floating-label">To</label>
              {formik?.errors?.toTime && formik?.touched?.toTime && (
                <div className="validation-schema-error">
                  {formik?.errors?.toTime}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="did-floating-label-content w-100 mt-4">
          <input
            className="did-floating-input"
            type="text"
            placeholder=""
            name="min_order"
            inputMode="numeric"
            value={formik?.values?.min_order}
            onChange={handleInputChange}
          />
          <label className="did-floating-label">
            Minimum order value for own delivery
          </label>
          {formik?.errors?.min_order && formik?.touched?.min_order && (
            <div className="validation-schema-error">
              {formik?.errors?.min_order}
            </div>
          )}
        </div>

        {/* <div className="mt-5">
          <span style={{ color: "grey" }}>POS (Yes/No)</span>
          <div className="switch-field mt-2">
            <input
              type="radio"
              id="pos-yes"
              name="pos-switch"
              defaultChecked={false}
              onChange={(e) => {
                setPos(e.target.checked);
              }}
            />
            <label for="pos-yes">Yes</label>
            <input
              type="radio"
              id="pos-no"
              name="pos-switch"
              defaultChecked={true}
              onChange={(e) => {
                setPos(!e.target.checked);
              }}
            />
            <label for="pos-no">No</label>
          </div>
        </div> */}

        {/* {pos && (
          <div className="mt-5">
            <span style={{ color: "grey" }}>Status</span>
            <div className="switch-field mt-2">
              <input
                type="radio"
                id="status-online"
                name="status-switch"
                defaultChecked={false}
              />
              <label for="status-online">Online</label>
              <input
                type="radio"
                id="status-offline"
                name="status-switch"
                defaultChecked={true}
              />
              <label for="status-offline">Offline</label>
            </div>
            <div className="did-floating-label-content w-100 mt-6">
              <input
                className="did-floating-input"
                type="text"
                placeholder=""
              />
              <label className="did-floating-label">Manufacture name</label>
            </div>
          </div>
        )} */}

        <div className="mt-5">
          <span style={{ color: "grey" }}>Coordinates</span>

          <div className="pl-2.5 mt-5">
            <div className="did-floating-label-content w-100">
              <input
                className="did-floating-input"
                type="text"
                value={formik?.values?.lat}
                name="lat"
                // inputMode="numeric"
                onChange={handleInputChange}
              />
              <label className="did-floating-label">Lat</label>
              {formik?.errors?.lat && formik?.touched?.lat && (
                <div className="validation-schema-error">
                  {formik?.errors?.lat}
                </div>
              )}
            </div>

            <div className="did-floating-label-content w-100 mt-2">
              <input
                className="did-floating-input"
                type="text"
                name="lng"
                value={formik?.values?.lng}
                // inputMode="numeric"
                onChange={handleInputChange}
              />
              <label className="did-floating-label">Lng</label>
              {formik?.errors?.lng && formik?.touched?.lng && (
                <div className="validation-schema-error">
                  {formik?.errors?.lng}
                </div>
              )}
            </div>

            <div className="flex">
              <button
                onClick={appGeoLocation}
                type="button"
                style={{ padding: "5px 10px" }}
              >
                Get
              </button>
              {spinner && (
                <div
                  style={{
                    width: "100vw",
                    height: "100vh",
                    position: "fixed",
                    background: "rgba(255,255,255,0.5)",
                    display: "flex",
                    zIndex: "9999",
                    top: "0",
                    left: "0",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <GridLoader color="#2f57ef" />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card_terms mt-3">
          <input
            type="checkbox"
            name="legalDeclaration"
            id="terms"
            onChange={handleCheckBoxChange}
          />
          <span>
            I have read and agree to the
            <strong
              href=""
              onClick={() => setOpen(true)}
              style={{ color: "#2f57ef", cursor: "pointer", marginLeft: "5px" }}
            >
              Legal Declaration
            </strong>
          </span>
        </div>
        {formik?.errors?.legalDeclaration &&
          formik?.touched?.legalDeclaration && (
            <div
              className="validation-schema-error"
              style={{ margin: "-5px 0 6px 10px ", fontSize: "11px" }}
            >
              {formik?.errors?.legalDeclaration}
            </div>
          )}


 {/* <div className="card_terms" style={{paddingTop: "1px"}}>
          <input
            type="checkbox"
            name="termsAndCondition"
            id="t&c"
            onChange={handleCheckBoxChange}
          />
          <span>
            I have read and agree to the
            <strong
              href=""
              onClick={() => setOpenTandC({open: true, proceed: false, submit: false})}
              style={{ color: "#2f57ef", cursor: "pointer", marginLeft: "5px" }}
            >
              Terms & Condition
            </strong>
      
          </span>
        </div>
        {formik?.errors?.termsAndCondition &&
          formik?.touched?.termsAndCondition && (
            <div
              className="validation-schema-error"
              style={{ margin: "-5px 0 6px 10px ", fontSize: "11px" }}
            >
              {formik?.errors?.termsAndCondition}
            </div>
          )} */}
        <button
          className="w-full mt-2"
          type="submit"
          onClick={handleSubmitForm}
        >
          Submit
        </button>
        {/* <button
          className="w-full mt-2"
          // type="submit"
          onClick={() => setOpenTandC({open: true, proceed: true, submit: false})}
        >
          Submit
        </button> */}
      </form>
      {showToasty && (
        <Toasty
          showToast={showToasty}
          message={toastyMessaga}
          isSuccess={toastifyStatus}
          time={ToastyTime}
        />
      )}
    </div>
  );
}

export default Form;
