export const ondclogo123 = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 841.89 399.37">
  <defs>
    <style>
      .cls-1 {
        fill: url(#linear-gradient-12);
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: url(#linear-gradient);
      }

      .cls-3 {
        fill: url(#linear-gradient-6);
      }

      .cls-4 {
        fill: url(#linear-gradient-11);
      }

      .cls-17 {
        fill: #231f20;
        font-family: Poppins-Regular, Poppins;
        font-size: 22px;
      }

      .cls-5 {
        fill: url(#linear-gradient-5);
      }

      .cls-18 {
        fill: none;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 1.5px;
      }

      .cls-6 {
        fill: #606161;
      }

      .cls-7 {
        fill: #939598;
      }

      .cls-8 {
        fill: url(#linear-gradient-2);
      }

      .cls-9 {
        fill: url(#linear-gradient-7);
      }

      .cls-10 {
        fill: url(#linear-gradient-3);
      }

      .cls-11 {
        fill: #f29c49;
      }

      .cls-12 {
        fill: url(#linear-gradient-9);
      }

      .cls-13 {
        fill: url(#linear-gradient-8);
      }

      .cls-14 {
        fill: #44823d;
      }

      .cls-15 {
        fill: url(#linear-gradient-10);
      }

      .cls-16 {
        fill: url(#linear-gradient-4);
      }
    </style>
    <linearGradient id="linear-gradient" x1="164.65" y1="191.02" x2="655.66" y2="307.18" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#1c75bc"/>
      <stop offset="1" stop-color="#00aeef"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="195.18" y1="61.94" x2="686.2" y2="178.09" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-3" x1="481.73" y1="118.43" x2="512.98" y2="125.32" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-4" x1="436.93" y1="180.32" x2="440.24" y2="181.05" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-5" x1="197.24" y1="121.72" x2="751.58" y2="244" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-6" x1="209.45" y1="175.82" x2="576.49" y2="300.7" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-7" x1="228.95" y1="118.54" x2="595.98" y2="243.41" gradientUnits="userSpaceOnUse">
      <stop offset=".05" stop-color="#1c75bc"/>
      <stop offset="1" stop-color="#00aeef"/>
    </linearGradient>
    <linearGradient id="linear-gradient-8" x1="460.52" y1="143.61" x2="585.98" y2="173.29" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-9" x1="453.07" y1="56.24" x2="607.07" y2="92.67" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-10" x1="384.85" y1="64.73" x2="510.32" y2="94.41" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-11" x1="338.77" y1="144.36" x2="516.27" y2="186.35" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-12" x1="423.25" y1="162.51" x2="549.78" y2="192.45" xlink:href="#linear-gradient"/>
  </defs>
  <g>
    <g>
      <g>
        <path className="cls-6" d="m85.66,333.82c-.5,1.15-1.17,2.16-2.04,3.02-.86.86-1.87,1.54-3.02,2.04-1.15.49-2.39.74-3.7.74h-2.75c-1.31,0-2.55-.25-3.71-.74-1.16-.49-2.17-1.18-3.04-2.04-.86-.86-1.54-1.87-2.04-3.02-.5-1.15-.74-2.39-.74-3.7v-20.79c0-1.31.25-2.55.74-3.7.49-1.15,1.17-2.16,2.04-3.02.86-.86,1.88-1.54,3.04-2.04,1.16-.49,2.4-.74,3.71-.74h2.75c1.31,0,2.55.25,3.7.74,1.15.5,2.16,1.18,3.02,2.04.86.86,1.54,1.87,2.04,3.02.49,1.15.74,2.39.74,3.7v20.79c0,1.31-.25,2.55-.74,3.7Zm-2.74-24.49c0-.86-.16-1.67-.49-2.42s-.77-1.4-1.32-1.96c-.56-.56-1.21-1-1.96-1.32-.75-.32-1.55-.49-2.42-.49h-2.43c-.86,0-1.67.16-2.42.49-.75.32-1.4.76-1.97,1.32-.57.56-1.01,1.21-1.34,1.96s-.49,1.55-.49,2.42v20.79c0,.86.16,1.67.49,2.42s.77,1.4,1.34,1.97c.57.57,1.22,1.01,1.97,1.34.75.32,1.55.49,2.42.49h2.43c.86,0,1.67-.16,2.42-.49.75-.32,1.4-.77,1.96-1.34.56-.57,1-1.22,1.32-1.97s.49-1.55.49-2.42v-20.79Z"/>
        <path className="cls-6" d="m110.33,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-.84c-1.24,0-2.39-.25-3.46-.76s-1.97-1.21-2.73-2.1v13.39h-3.32v-38.72h1.11l1.81,2.81c.76-1.03,1.7-1.84,2.85-2.44,1.14-.6,2.39-.9,3.74-.9h.84c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v12.47c0,1.12-.21,2.17-.63,3.16Zm-2.69-15.82c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03-.59-.25-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.53,1.03c-.43.43-.77.94-1.03,1.53-.25.58-.38,1.21-.38,1.88v12.85c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.53,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38.58-.25,1.09-.59,1.53-1.03s.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-12.85Z"/>
        <path className="cls-6" d="m134.34,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-2.21c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59-.42-.99-.63-2.04-.63-3.16v-12.47c0-1.12.21-2.17.63-3.16.42-.99,1-1.85,1.74-2.59.74-.74,1.6-1.32,2.59-1.74s2.04-.63,3.16-.63h2.21c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v6.51h-15.15v6.16c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.53,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38.58-.25,1.09-.59,1.53-1.03s.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-1.84l3.32.54v1.11c0,1.12-.21,2.17-.63,3.16Zm-2.69-15.82c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03-.59-.25-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.53,1.03c-.43.43-.77.94-1.03,1.53-.25.58-.38,1.21-.38,1.88v4.1h11.82v-4.1Z"/>
        <path className="cls-6" d="m155.65,339.08v-20.25c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03-.59-.25-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.53,1.03c-.43.43-.77.94-1.03,1.53-.25.58-.38,1.21-.38,1.88v20.25h-3.32v-27.65h1.11l1.81,2.81c.76-1.03,1.7-1.84,2.85-2.44,1.14-.6,2.39-.9,3.74-.9h.84c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v20.06h-3.32Z"/>
        <path className="cls-6" d="m197.18,339.08l-16.14-28.19-.32-1.67v29.86h-3.48v-38.71h1.81l16.14,28.21.35,1.65v-29.86h3.48v38.71h-1.84Z"/>
        <path className="cls-6" d="m222.95,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-2.21c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59-.42-.99-.63-2.04-.63-3.16v-12.47c0-1.12.21-2.17.63-3.16.42-.99,1-1.85,1.74-2.59.74-.74,1.6-1.32,2.59-1.74s2.04-.63,3.16-.63h2.21c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v6.51h-15.15v6.16c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.53,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38.58-.25,1.09-.59,1.53-1.03s.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-1.84l3.32.54v1.11c0,1.12-.21,2.17-.63,3.16Zm-2.69-15.82c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03-.59-.25-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.53,1.03c-.43.43-.77.94-1.03,1.53-.25.58-.38,1.21-.38,1.88v4.1h11.82v-4.1Z"/>
        <path className="cls-6" d="m239.19,339.08c-1.01,0-1.96-.19-2.85-.57s-1.66-.9-2.32-1.57-1.18-1.44-1.57-2.32c-.39-.88-.58-1.83-.58-2.83v-17.2h-4.97v-3.16h4.97v-6.64l3.32-.54v7.18h7.02v3.16h-7.02v17.36c0,.56.1,1.08.31,1.55.21.48.49.9.85,1.25.36.36.78.64,1.27.85.49.21,1.01.31,1.57.31h3.02v3.16h-3.02Z"/>
        <path className="cls-6" d="m270.48,339.08h-1.81l-6.48-20.84-.11-1.43-.11,1.43-6.48,20.84h-1.84l-9.23-27.65h3.37l6.59,20.9.11,1.43.11-1.43,6.48-20.9h2l6.45,20.9.11,1.43.11-1.43,6.59-20.9h3.37l-9.23,27.65Z"/>
        <path className="cls-6" d="m300.32,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-2.21c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59-.42-.99-.63-2.04-.63-3.16v-12.47c0-1.12.21-2.17.63-3.16.42-.99,1-1.85,1.74-2.59.74-.74,1.6-1.32,2.59-1.74s2.04-.63,3.16-.63h2.21c1.12,0,2.17.21,3.16.63.99.42,1.85,1,2.59,1.74.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v12.47c0,1.12-.21,2.17-.63,3.16Zm-2.69-15.82c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03-.59-.25-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.53,1.03c-.43.43-.77.94-1.03,1.53-.25.58-.38,1.21-.38,1.88v12.85c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.53,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38.58-.25,1.09-.59,1.53-1.03s.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-12.85Z"/>
        <path className="cls-6" d="m314.62,314.59c-.67,0-1.29.13-1.88.38s-1.09.59-1.53,1.03c-.43.43-.77.94-1.03,1.53-.25.58-.38,1.21-.38,1.88v19.68h-3.32v-27.65h1.11l1.81,2.81c.76-1.03,1.7-1.84,2.85-2.44,1.14-.6,2.39-.9,3.74-.9h1.94v3.7h-3.32Z"/>
        <path className="cls-6" d="m322.37,339.08v-38.71h3.32v38.71h-3.32Zm15.25,0l-11.28-13.82,11.28-13.82h4.32l-11.55,13.82,11.55,13.82h-4.32Z"/>
        <path className="cls-6" d="m366.38,303.52c-.56,0-1.08.1-1.55.31-.48.21-.9.49-1.26.85-.36.36-.64.78-.85,1.27-.21.49-.31,1.01-.31,1.57v3.91h6.45v3.16h-6.45v24.49h-3.32v-24.49h-4.99v-3.16h4.99v-3.75c0-1.01.19-1.96.57-2.85.38-.89.9-1.67,1.57-2.34.67-.67,1.44-1.19,2.32-1.57.88-.38,1.83-.57,2.83-.57h3.05v3.16h-3.05Z"/>
        <path className="cls-6" d="m389.48,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-2.21c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59-.42-.99-.63-2.04-.63-3.16v-12.47c0-1.12.21-2.17.63-3.16.42-.99,1-1.85,1.74-2.59.74-.74,1.6-1.32,2.59-1.74s2.04-.63,3.16-.63h2.21c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v12.47c0,1.12-.21,2.17-.63,3.16Zm-2.69-15.82c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03-.59-.25-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.53,1.03c-.43.43-.77.94-1.03,1.53-.25.58-.38,1.21-.38,1.88v12.85c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.53,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38.58-.25,1.09-.59,1.53-1.03s.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-12.85Z"/>
        <path className="cls-6" d="m403.77,314.59c-.67,0-1.29.13-1.88.38s-1.09.59-1.53,1.03c-.43.43-.77.94-1.03,1.53-.25.58-.38,1.21-.38,1.88v19.68h-3.32v-27.65h1.11l1.81,2.81c.76-1.03,1.7-1.84,2.85-2.44,1.14-.6,2.39-.9,3.74-.9h1.94v3.7h-3.32Z"/>
        <path className="cls-6" d="m444.18,333.28c-.49,1.15-1.17,2.16-2.04,3.02s-1.88,1.54-3.04,2.04c-1.16.49-2.4.74-3.71.74h-12.26v-38.71h12.26c1.31,0,2.55.25,3.71.74,1.16.49,2.17,1.17,3.04,2.04.86.86,1.54,1.88,2.04,3.04s.74,2.4.74,3.71v19.68c0,1.31-.25,2.55-.74,3.7Zm-2.74-23.38c0-.86-.16-1.67-.49-2.42-.32-.75-.77-1.4-1.34-1.97s-1.22-1.01-1.97-1.34-1.55-.49-2.42-.49h-8.61v32.07h8.61c.86,0,1.67-.16,2.42-.49s1.4-.76,1.97-1.32c.57-.56,1.01-1.21,1.34-1.96.32-.75.49-1.55.49-2.42v-19.68Z"/>
        <path className="cls-6" d="m450.72,304.79v-4.43h3.86v4.43h-3.86Zm.27,34.29v-27.65h3.32v27.65h-3.32Z"/>
        <path className="cls-6" d="m479.06,345.64c-.42.94-1,1.74-1.74,2.4s-1.6,1.18-2.59,1.55c-.99.37-2.04.55-3.16.55h-1.38c-1.12,0-2.17-.19-3.16-.55-.99-.37-1.85-.89-2.59-1.55s-1.32-1.47-1.74-2.4c-.42-.94-.63-1.96-.63-3.08v-.11c0-1.08.2-2.07.59-2.97.4-.9.94-1.68,1.62-2.35-.86-.52-1.55-1.23-2.06-2.12s-.77-1.88-.77-2.96c0-.97.21-1.88.62-2.71s.98-1.52,1.7-2.04c-.86-.76-1.55-1.66-2.05-2.73-.5-1.06-.76-2.22-.76-3.48v-2.08c0-1.12.21-2.17.63-3.16s1-1.85,1.74-2.59c.74-.74,1.6-1.32,2.59-1.74.99-.42,2.04-.63,3.16-.63h1.38c1.35,0,2.6.3,3.74.9,1.14.6,2.09,1.42,2.85,2.44l1.81-2.81h1.11v9.67c0,1.12-.21,2.17-.63,3.16-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-4.81c-.38,0-.74.08-1.08.23s-.64.36-.9.61c-.26.25-.46.55-.61.9-.14.35-.22.71-.22,1.09s.07.74.22,1.08c.14.34.35.64.61.9.26.26.56.46.9.61s.7.22,1.08.22h4.54c1.12,0,2.17.19,3.16.55.99.37,1.85.89,2.59,1.55s1.32,1.47,1.74,2.4c.42.94.63,1.96.63,3.08v.11c0,1.12-.21,2.14-.63,3.08Zm-2.39-26.81c0-.67-.13-1.29-.38-1.88-.25-.58-.6-1.09-1.04-1.53-.44-.43-.95-.77-1.54-1.03s-1.21-.38-1.88-.38h-2.75c-.67,0-1.29.13-1.88.38s-1.09.59-1.52,1.03-.77.94-1.03,1.53-.38,1.21-.38,1.88v2.46c0,.67.13,1.29.38,1.88s.59,1.09,1.03,1.53.94.77,1.52,1.03c.58.25,1.21.38,1.88.38h2.75c.67,0,1.29-.13,1.88-.38.58-.25,1.1-.59,1.54-1.03.44-.43.79-.94,1.04-1.53.25-.58.38-1.21.38-1.88v-2.46Zm-.14,23.46c0-.67-.13-1.26-.38-1.8-.25-.53-.59-.98-1.03-1.34-.43-.36-.94-.64-1.52-.84-.59-.2-1.21-.3-1.88-.3h-1.7c-.67,0-1.29.1-1.88.3-.58.2-1.09.48-1.53.84s-.77.81-1.03,1.34c-.25.53-.38,1.13-.38,1.8v.43c0,.67.13,1.26.38,1.78.25.52.59.97,1.03,1.34.43.37.94.65,1.53.85.58.2,1.21.3,1.88.3h1.7c.67,0,1.29-.1,1.88-.3.58-.2,1.09-.48,1.52-.85.43-.37.77-.81,1.03-1.34.25-.52.38-1.12.38-1.78v-.43Z"/>
        <path className="cls-6" d="m485.8,304.79v-4.43h3.86v4.43h-3.86Zm.27,34.29v-27.65h3.32v27.65h-3.32Z"/>
        <path className="cls-6" d="m506.1,339.08c-1.01,0-1.96-.19-2.85-.57s-1.66-.9-2.32-1.57c-.66-.67-1.18-1.44-1.57-2.32-.39-.88-.58-1.83-.58-2.83v-17.2h-4.97v-3.16h4.97v-6.64l3.32-.54v7.18h7.02v3.16h-7.02v17.36c0,.56.1,1.08.31,1.55.21.48.49.9.85,1.25s.78.64,1.27.85c.49.21,1.01.31,1.57.31h3.02v3.16h-3.02Z"/>
        <path className="cls-6" d="m531.48,339.08l-1.84-2.75c-.76.99-1.7,1.79-2.84,2.39-1.13.6-2.38.9-3.73.9h-.84c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59-.42-.99-.63-2.04-.63-3.16v-.27c0-1.12.21-2.17.63-3.16.42-.99,1-1.85,1.74-2.59s1.6-1.32,2.59-1.74c.99-.42,2.04-.63,3.16-.63h7.02v-4.27c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03s-1.21-.38-1.88-.38h-1.94c-.67,0-1.29.13-1.88.38-.59.25-1.09.59-1.53,1.03-.43.43-.77.94-1.03,1.53s-.38,1.21-.38,1.88v1l-3.32-.54v-.27c0-1.12.21-2.17.63-3.16.42-.99,1-1.85,1.74-2.59.74-.74,1.6-1.32,2.59-1.74s2.04-.63,3.16-.63h1.94c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v20.06h-1.11Zm-2.21-13.39h-7.02c-.67,0-1.29.13-1.88.38s-1.09.6-1.52,1.04c-.43.44-.77.95-1.03,1.54s-.38,1.21-.38,1.88v1.16c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.52,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38s1.09-.59,1.53-1.03.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-5.99Z"/>
        <path className="cls-6" d="m539.23,339.08v-38.71h3.32v38.71h-3.32Z"/>
        <path className="cls-6" d="m582.39,333.82c-.49,1.15-1.17,2.16-2.04,3.02s-1.87,1.54-3.02,2.04c-1.15.49-2.38.74-3.7.74h-2.75c-1.31,0-2.55-.25-3.71-.74-1.16-.49-2.17-1.18-3.04-2.04-.86-.86-1.54-1.87-2.04-3.02-.49-1.15-.74-2.39-.74-3.7v-20.79c0-1.31.25-2.55.74-3.7.49-1.15,1.18-2.16,2.04-3.02.86-.86,1.88-1.54,3.04-2.04,1.16-.49,2.4-.74,3.71-.74h2.75c1.31,0,2.55.25,3.7.74,1.15.5,2.16,1.18,3.02,2.04.86.86,1.54,1.87,2.04,3.02.49,1.15.74,2.39.74,3.7v1.57l-3.48.54v-2.1c0-.86-.16-1.67-.48-2.42s-.77-1.4-1.32-1.96-1.21-1-1.96-1.32-1.55-.49-2.42-.49h-2.43c-.86,0-1.67.16-2.42.49s-1.4.76-1.97,1.32c-.57.56-1.01,1.21-1.34,1.96s-.49,1.55-.49,2.42v20.79c0,.86.16,1.67.49,2.42s.77,1.4,1.34,1.97c.57.57,1.22,1.01,1.97,1.34s1.55.49,2.42.49h2.43c.86,0,1.67-.16,2.42-.49s1.4-.77,1.96-1.34,1-1.22,1.32-1.97.48-1.55.48-2.42v-2.11l3.48.57v1.54c0,1.31-.25,2.55-.74,3.7Z"/>
        <path className="cls-6" d="m606.5,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74s-2.04.63-3.16.63h-2.21c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59-.42-.99-.63-2.04-.63-3.16v-12.47c0-1.12.21-2.17.63-3.16s1-1.85,1.74-2.59c.74-.74,1.6-1.32,2.59-1.74.99-.42,2.04-.63,3.16-.63h2.21c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v12.47c0,1.12-.21,2.17-.63,3.16Zm-2.69-15.82c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03s-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.52,1.03-.77.94-1.03,1.53-.38,1.21-.38,1.88v12.85c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.52,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38s1.09-.59,1.53-1.03.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-12.85Z"/>
        <path className="cls-6" d="m641.85,339.08v-20.25c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03s-1.21-.38-1.88-.38h-1.65c-.67,0-1.29.13-1.88.38s-1.1.59-1.54,1.03c-.44.43-.79.94-1.04,1.53-.25.58-.38,1.21-.38,1.88v20.25h-3.29v-20.25c0-.67-.13-1.29-.38-1.88s-.6-1.09-1.04-1.53c-.44-.43-.95-.77-1.54-1.03s-1.21-.38-1.88-.38h-1.65c-.67,0-1.29.13-1.88.38s-1.09.59-1.52,1.03-.77.94-1.03,1.53-.38,1.21-.38,1.88v20.25h-3.32v-27.65h1.11l1.81,2.81c.76-1.03,1.71-1.84,2.85-2.44,1.14-.6,2.39-.9,3.74-.9h.27c1.57,0,2.98.39,4.24,1.16,1.26.77,2.23,1.8,2.92,3.08.34-.63.76-1.21,1.27-1.73.5-.52,1.06-.97,1.67-1.34.61-.37,1.28-.66,2-.86.72-.21,1.47-.31,2.24-.31h.27c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v20.06h-3.32Z"/>
        <path className="cls-6" d="m679.89,339.08v-20.25c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03s-1.21-.38-1.88-.38h-1.65c-.67,0-1.29.13-1.88.38s-1.1.59-1.54,1.03c-.44.43-.79.94-1.04,1.53-.25.58-.38,1.21-.38,1.88v20.25h-3.29v-20.25c0-.67-.13-1.29-.38-1.88s-.6-1.09-1.04-1.53c-.44-.43-.95-.77-1.54-1.03s-1.21-.38-1.88-.38h-1.65c-.67,0-1.29.13-1.88.38s-1.09.59-1.52,1.03-.77.94-1.03,1.53-.38,1.21-.38,1.88v20.25h-3.32v-27.65h1.11l1.81,2.81c.76-1.03,1.71-1.84,2.85-2.44,1.14-.6,2.39-.9,3.74-.9h.27c1.57,0,2.98.39,4.24,1.16,1.26.77,2.23,1.8,2.92,3.08.34-.63.76-1.21,1.27-1.73.5-.52,1.06-.97,1.67-1.34.61-.37,1.28-.66,2-.86.72-.21,1.47-.31,2.24-.31h.27c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v20.06h-3.32Z"/>
        <path className="cls-6" d="m706.58,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-2.21c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59s-.63-2.04-.63-3.16v-12.47c0-1.12.21-2.17.63-3.16s1-1.85,1.74-2.59c.74-.74,1.6-1.32,2.59-1.74.99-.42,2.04-.63,3.16-.63h2.21c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v6.51h-15.15v6.16c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.52,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38s1.09-.59,1.53-1.03.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-1.84l3.32.54v1.11c0,1.12-.21,2.17-.63,3.16Zm-2.69-15.82c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03s-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.52,1.03-.77.94-1.03,1.53-.38,1.21-.38,1.88v4.1h11.83v-4.1Z"/>
        <path className="cls-6" d="m720.88,314.59c-.67,0-1.29.13-1.88.38s-1.09.59-1.52,1.03-.77.94-1.03,1.53-.38,1.21-.38,1.88v19.68h-3.32v-27.65h1.11l1.81,2.81c.76-1.03,1.71-1.84,2.85-2.44,1.14-.6,2.39-.9,3.74-.9h1.94v3.7h-3.32Z"/>
        <path className="cls-6" d="m745.35,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-2.21c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59s-.63-2.04-.63-3.16v-12.47c0-1.12.21-2.17.63-3.16.42-.99,1-1.85,1.74-2.59.74-.74,1.6-1.32,2.59-1.74.99-.42,2.04-.63,3.16-.63h2.21c1.12,0,2.17.21,3.16.63.99.42,1.85,1,2.59,1.74.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v1.11l-3.32.54v-1.84c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03s-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.52,1.03-.77.94-1.03,1.53-.38,1.21-.38,1.88v12.85c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.52,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38s1.09-.59,1.53-1.03.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-1.84l3.32.54v1.11c0,1.12-.21,2.17-.63,3.16Z"/>
        <path className="cls-6" d="m769.35,334.65c-.42.99-1,1.85-1.74,2.59-.74.74-1.6,1.32-2.59,1.74-.99.42-2.04.63-3.16.63h-2.21c-1.12,0-2.17-.21-3.16-.63-.99-.42-1.85-1-2.59-1.74-.74-.74-1.32-1.6-1.74-2.59s-.63-2.04-.63-3.16v-12.47c0-1.12.21-2.17.63-3.16s1-1.85,1.74-2.59c.74-.74,1.6-1.32,2.59-1.74.99-.42,2.04-.63,3.16-.63h2.21c1.12,0,2.17.21,3.16.63s1.85,1,2.59,1.74c.74.74,1.32,1.6,1.74,2.59s.63,2.04.63,3.16v6.51h-15.15v6.16c0,.67.13,1.29.38,1.88.25.59.59,1.09,1.03,1.53.43.43.94.77,1.52,1.03s1.21.38,1.88.38h2.21c.67,0,1.29-.13,1.88-.38s1.09-.59,1.53-1.03.77-.94,1.03-1.53c.25-.58.38-1.21.38-1.88v-1.84l3.32.54v1.11c0,1.12-.21,2.17-.63,3.16Zm-2.69-15.82c0-.67-.13-1.29-.38-1.88-.25-.58-.59-1.09-1.03-1.53-.43-.43-.94-.77-1.53-1.03s-1.21-.38-1.88-.38h-2.21c-.67,0-1.29.13-1.88.38s-1.09.59-1.52,1.03-.77.94-1.03,1.53-.38,1.21-.38,1.88v4.1h11.83v-4.1Z"/>
      </g>
      <path className="cls-2" d="m134.2,95.69c26.52,0,47.84,8.06,63.97,24.19,16.13,16.13,24.2,37.45,24.2,63.97s-8.1,47.7-24.28,63.89c-16.19,16.19-37.49,24.28-63.88,24.28s-47.56-8.12-63.8-24.37c-16.24-16.24-24.36-37.51-24.36-63.8s8.09-47.7,24.28-63.88c16.19-16.19,37.49-24.28,63.89-24.28Zm0,35.3c-20.27,0-35.39,8-45.37,24.02-5,8.04-7.49,17.65-7.49,28.84,0,10.22,2.24,19.38,6.72,27.47,4.7,8.5,11.48,14.95,20.32,19.38,8.04,4.01,16.64,6.02,25.83,6.02,20.26,0,35.39-8.03,45.37-24.1,5-7.98,7.49-17.56,7.49-28.76,0-16.76-5.39-29.9-16.19-39.43-10.11-8.95-22.33-13.43-36.68-13.43Z"/>
      <path className="cls-8" d="m748.48,221.91l25.74,25.74c-10.33,10.56-23.36,17.74-39.09,21.53-7.69,1.89-15.93,2.84-24.71,2.84-26.29,0-47.55-8.12-63.8-24.37-16.24-16.24-24.36-37.51-24.36-63.8s8.09-47.7,24.28-63.88c16.18-16.19,37.48-24.28,63.88-24.28s47.85,8.06,63.98,24.19l-25.92,25.92c-10.1-9.88-22.79-14.81-38.06-14.81-20.26,0-35.38,8-45.37,24.02-4.99,8.04-7.49,17.65-7.49,28.84,0,10.22,2.25,19.38,6.72,27.47,4.71,8.5,11.48,14.95,20.32,19.38,8.04,4.01,16.65,6.02,25.83,6.02,15.27,0,27.96-4.93,38.06-14.81Z"/>
      <g>
        <path className="cls-10" d="m499.85,127.22c2.65,0,4.8-2.15,4.8-4.8s-2.15-4.79-4.8-4.79-4.8,2.15-4.8,4.79,2.15,4.8,4.8,4.8Z"/>
        <path className="cls-16" d="m438.33,180.78c0,.57,1.01.58,1.02,0,0-.29-.22-.51-.51-.51s-.51.22-.51.51Z"/>
      </g>
      <path className="cls-5" d="m580.79,119.88c-10.43-10.42-23.03-17.45-37.78-21.14l-9.03,9.04c1.93,4.5,3.01,9.45,3.01,14.65,0,3.98-.63,7.82-1.8,11.42,6.72,2.22,12.83,5.72,18.31,10.57,10.79,9.53,16.18,22.67,16.18,39.43,0,11.2-2.49,20.78-7.49,28.76-9.98,16.07-25.1,24.1-45.37,24.1h-52.87v-45.93c-3.99,9.97-13.73,17.03-25.1,17.03-3.61,0-7.05-.72-10.2-2v66.21h88.17c26.4,0,47.7-8.09,63.88-24.28,16.2-16.19,24.28-37.48,24.28-63.89s-8.06-47.84-24.18-63.97Z"/>
      <g>
        <path className="cls-7" d="m525.6,132.67l-23.47-10.01c-1.29-.55-2.61.76-2.05,2.05l10.01,23.47c.25.59.84.98,1.51.95.65-.03,1.22-.45,1.42-1.07l3.09-9.37,9.37-3.09c.62-.2,1.05-.77,1.07-1.42.03-.65-.35-1.25-.95-1.51Zm-11.22,3.29c-.47.16-.84.52-1,1l-2.04,6.18-6.86-16.08,16.08,6.86-6.18,2.04Z"/>
        <path className="cls-14" d="m499.85,105.14c-9.53,0-17.29,7.76-17.29,17.29s7.76,17.29,17.29,17.29c1.25,0,2.27-1.02,2.27-2.27s-1.02-2.27-2.27-2.27c-7.03,0-12.75-5.72-12.75-12.75s5.72-12.75,12.75-12.75,12.75,5.72,12.75,12.75c0,1.25,1.02,2.27,2.27,2.27s2.27-1.02,2.27-2.27c0-9.53-7.76-17.29-17.29-17.29Z"/>
        <path className="cls-11" d="m519.21,103.07c-5.19-5.19-12.07-8.05-19.36-8.05h0c-7.29,0-14.17,2.86-19.36,8.05-10.67,10.67-10.67,28.04,0,38.72,5.19,5.19,12.07,8.05,19.36,8.05,1.25,0,2.27-1.02,2.27-2.27s-1.02-2.27-2.27-2.27c-12.61,0-22.87-10.26-22.87-22.87s10.26-22.87,22.87-22.87,22.87,10.26,22.87,22.87c0,1.25,1.02,2.27,2.27,2.27s2.27-1.02,2.27-2.27c0-7.29-2.86-14.17-8.05-19.36Z"/>
      </g>
    </g>
    <g>
      <polygon className="cls-3" points="233.05 272.02 268.35 272.02 268.35 159.09 338.86 215.49 338.86 180.33 233.05 95.69 233.05 272.02"/>
      <path className="cls-9" d="m462.71,122.43c0-5.2,1.07-10.15,3.01-14.65l-6.16-6.17c-6.55.66-12.67-1.73-17.08-5.92h-78.44v148.08l35.3,28.24v-141.02h64.38c-.65-2.75-1-5.62-1-8.56Z"/>
    </g>
    <g>
      <path className="cls-13" d="m542.29,154.25c-1.96,0-3.78.57-5.36,1.5l-9.02-9.02c-1.16,1.34-2.41,2.59-3.75,3.75l9.02,9.02c-.94,1.58-1.51,3.4-1.51,5.36,0,5.85,4.76,10.61,10.61,10.61s10.61-4.76,10.61-10.61-4.76-10.61-10.61-10.61Zm0,15.91c-2.93,0-5.3-2.38-5.3-5.3s2.38-5.3,5.3-5.3,5.3,2.38,5.3,5.3-2.38,5.3-5.3,5.3Z"/>
      <path className="cls-12" d="m552.9,56.11c-8.78,0-15.91,7.14-15.91,15.91,0,2.75.7,5.33,1.93,7.59l-14.75,14.75c1.34,1.16,2.59,2.41,3.75,3.75l14.32-14.32c2.82,2.56,6.56,4.14,10.67,4.14,8.78,0,15.91-7.14,15.91-15.91s-7.14-15.91-15.91-15.91Zm0,26.52c-5.85,0-10.61-4.76-10.61-10.61s4.76-10.61,10.61-10.61,10.61,4.76,10.61,10.61-4.76,10.61-10.61,10.61Z"/>
      <path className="cls-15" d="m457.41,90.6c1.96,0,3.78-.57,5.36-1.5l9.02,9.02c1.16-1.34,2.41-2.59,3.75-3.75l-9.02-9.02c.93-1.58,1.5-3.39,1.5-5.36,0-5.85-4.76-10.61-10.61-10.61s-10.61,4.76-10.61,10.61,4.76,10.61,10.61,10.61Zm0-15.91c2.93,0,5.3,2.38,5.3,5.3s-2.38,5.3-5.3,5.3-5.3-2.38-5.3-5.3,2.38-5.3,5.3-5.3Z"/>
      <path className="cls-4" d="m475.54,150.48c-1.34-1.16-2.59-2.41-3.75-3.75l-22.27,22.27c-2.83-2.56-6.56-4.14-10.67-4.14-8.78,0-15.91,7.14-15.91,15.91s7.14,15.91,15.91,15.91,15.91-7.14,15.91-15.91c0-2.75-.7-5.33-1.93-7.59l22.71-22.71Zm-36.7,40.9c-5.85,0-10.61-4.76-10.61-10.61s4.76-10.61,10.61-10.61,10.61,4.76,10.61,10.61-4.76,10.61-10.61,10.61Z"/>
      <path className="cls-1" d="m502.5,170.41v-10.95c-.88.06-1.76.1-2.66.1s-1.77-.04-2.65-.1v10.95c-7.51,1.27-13.26,7.8-13.26,15.68,0,8.78,7.14,15.91,15.91,15.91s15.91-7.14,15.91-15.91c0-7.87-5.75-14.41-13.26-15.68Zm-2.66,26.29c-5.85,0-10.61-4.76-10.61-10.61s4.76-10.61,10.61-10.61,10.61,4.76,10.61,10.61-4.76,10.61-10.61,10.61Z"/>
    </g>
  </g>
  <g>
    <text className="cls-17" transform="translate(770.84 89.67) scale(.97 1)"><tspan x="0" y="0">R</tspan></text>
    <circle className="cls-18" cx="776.49" cy="81.66" r="13.35"/>
  </g>
</svg>`