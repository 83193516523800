import React, { useRef, useState } from 'react';
import Quagga from 'quagga';

const NewBarcodeScanner = () => {
  const videoRef = useRef(null);
  const inputRef = useRef(null);
  const [scannedCode, setScannedCode] = useState('');

  const startScanner = () => {
    const constraints = {
      video: {
        facingMode: "environment" // or "user" for front camera
      }
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        initScanner();
      })
      .catch(error => console.error('Error accessing media devices.', error));
  };

  const initScanner = () => {
    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: videoRef.current,
      },
      decoder: {
        readers: ["ean_reader", "upc_reader"] // or any other supported types
      },
    }, (err) => {
      if (err) {
        console.error(err);
        return;
      }
      Quagga.start();
    });

    Quagga.onDetected((data) => {
      setScannedCode(data.codeResult.code);
      Quagga.stop();
    });
  };

  return (
    <div className="scanner-container">
      <input ref={inputRef} type="file" accept="video/*" capture="environment" />
      <video ref={videoRef} className="scanner-video" autoPlay={true}/>
      <p>Scanned Code: {scannedCode}</p>
      <button onClick={startScanner}>Start Scanner</button>
    </div>
  );
};

export default NewBarcodeScanner;
