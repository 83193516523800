import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ondcLogo from "../../assets/ondc_logo.svg";
import { ondclogo123 } from "../../assets/OndcLogo";
import { OrderInvoiceData } from "../../services/Orders";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
// import PDFFile from "../../components/PDFFile";

const HtmlRenderer = () => {
  const params = useParams();
  const { order_no } = params;
  const [showHtml, setShowHtml] = useState(true);
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceTableData, setInvoiceTableData] = useState("");
  const [totalAmount, setTotalAmount] = useState({});

  const fetchInvoiceData = async () => {
    const result = await OrderInvoiceData(order_no.slice(0, -4));
    const itemData = result?.data?.data[0];
    const itemDetails = result?.data?.details;
    const tableData = itemDetails
      .map((e, index) => {
        const taxableValue = e?.price * (100 / (100 + (e?.tax ?? 0)));
        return `<tr>
  <td>${index + 1}</td>
  <td>${e?.sku_name}</td>
  <td>2005 99 00</td>
  <td>${e?.qty}</td>
  <td>${e?.price}</td>
  <td>${e?.qty * e?.price}</td>
  <td>${e?.discount}</td>
  <td>${taxableValue}</td>
  <td align="center">0
       <br/>${e?.price - taxableValue}</td>
  <td align="center">${
    Number(e?.price) * Number(e?.qty) - Number(e?.discount)
  }<br/></td>
</tr>`;
      })
      .join();

    const totalPrice = itemDetails.reduce(
      (total, e) => total + (e?.price ?? 0),
      0
    );
    const totalQty = itemDetails.reduce((total, e) => total + (e?.qty ?? 0), 0);
    const totalDiscount = itemDetails.reduce((total, e) => total + (Number(e?.discount) ?? 0), 0);
    const grossValue = itemDetails.reduce((total, e) => total + (e?.qty * e?.price), 0);
    const totalTaxableValue = itemDetails.reduce((total, e) => {
      const taxableValue = e?.price * (100 / (100 + (e?.tax ?? 0)));
      return total + taxableValue;
    }, 0);
    const igstAmount = itemDetails.reduce((total, e) => {
        const taxableValue = e?.price - (e?.price * (100 / (100 + (e?.tax ?? 0))));
        return total + taxableValue;
      }, 0);
    const totalFinalPrice = itemDetails.reduce((total, e) => {
      const finalPrice =
        Number(e?.price) * Number(e?.qty) - Number(e?.discount);
      return total + finalPrice;
    }, 0);
    const totalAmountInWord =  totalAmountInWords(totalFinalPrice);
    setTotalAmount({grossValue,totalDiscount, totalTaxableValue, igstAmount, totalFinalPrice, totalAmountInWord})
    
    setInvoiceTableData(tableData);
    setInvoiceData({
      orderNumber: itemData?.order_no,
      name: itemData?.shipping_name,
      address: itemData?.shipping_address,
      contact: itemData?.s_phone,
      address2:
        itemData?.shipping_city +
        " - " +
        itemData?.shipping_postal_code +
        ", " +
        itemData?.shipping_state +
        ", INDIA",
      noOfItems: itemDetails.length,
    });
  };

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  function totalAmountInWords(amount) {
    var words = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
                 "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    var tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    function convertLessThanThousand(number) {
        var result = "";
        if (number % 100 < 20) {
            result = words[number % 100];
            number = Math.floor(number / 100);
        } else {
            result = words[number % 10];
            number = Math.floor(number / 10);

            result = tens[number % 10] + " " + result;
            number = Math.floor(number / 10);
        }
        if (number > 0) {
            result = words[number] + " Hundred " + result;
        }
        return result;
    }
    if (amount == 0) {
        return "Zero Rupees";
    }
    var inWords = "";
    if (amount < 0) {
        inWords += "Minus ";
        amount = -amount;
    }
    if (Math.floor(amount / 10000000) > 0) {
        inWords += convertLessThanThousand(Math.floor(amount / 10000000)) + " Crore ";
        amount %= 10000000;
    }
    if (Math.floor(amount / 100000) > 0) {
        inWords += convertLessThanThousand(Math.floor(amount / 100000)) + " Lakh ";
        amount %= 100000;
    }
    if (Math.floor(amount / 1000) > 0) {
        inWords += convertLessThanThousand(Math.floor(amount / 1000)) + " Thousand ";
        amount %= 1000;
    }
    if (Math.floor(amount / 100) > 0) {
        inWords += convertLessThanThousand(Math.floor(amount / 100)) + " Hundred ";
        amount %= 100;
    }
    if (amount > 0) {
        if (inWords != "") {
            inWords += "and ";
        }
        inWords += convertLessThanThousand(amount);
    }
    inWords += " Rupees";
    return inWords.trim();
}





  return (
    <div>
      {showHtml && (
        <div id="html-content"
          dangerouslySetInnerHTML={{
            __html: `<html lang="en">
        <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <title>Invoice</title>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet">
      </head>
        <body>
            <div class="container-fluid">
                <div class="row">
                    <h1 align="center">TAX INVOICE</h1>
                    <div class="col-6 g-5">
                        <span><b>Bill to / Ship to:<br/>
                            ${invoiceData?.name}</b></br>
                            Address: ${invoiceData?.address}</br>
                        ${invoiceData?.address2}<br>
                        Contact No: ${invoiceData?.contact}
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-center g-5">
                    <div class="w-40">
                    ${ondclogo123}
                    </div>
                    </div>
                    <div class="col-6 g-5">
                        <table className="table table-bordered border-dark">
                            <tbody>
                                <tr>
                                    <td>Reference Number</td>
                                    <td><b>${invoiceData?.orderNumber}</b></td> 
                                </tr>
                                <tr>
                                    <td>GST Invoice No</td>
                                    <td>TTTHR23ES020946</td> 
                                </tr>
                                <tr>
                                    <td>Date of Invoice</td>
                                    <td>07-Mar-2024</td>
                                </tr>
                                <tr>
                                    <td>Slot</td>
                                    <td>2024-03-04</td>
                                </tr>
                                <tr>
                                    <td>No of Items</td>
                                    <td>${invoiceData?.noOfItems}</td>      
                                </tr>
                                <tr>
                                    <td>Place of Supply</td>
                                    <td>Haryana</td>     
                                </tr>   
                            </tbody>
                        </table>
                    </div>
                    <div class="col-6 g-5">
                        <span><b>Seller:<br/>
                            THE TECH TONIC</b><br/>
                            Mustakil no. 118// Khasra no. 5/2/1/2, 6/1, 15/2, 16/1/1,<br/>
                        Village Bhondsi, District Gurgaon-122102<br/>
                        HARYANA<br/>
                        GSTIN: 06AALFT2322H1ZB<br/>
                        FSSAI No: 10821005001374<br/>
                    </div>
                    
                        <table class="table table-bordered border-dark">
                            <tbody>
                                <tr>
                                    <th>S No</th>
                                    <th class="w-30">Item Description</th>
                                    <th>HSN/SAC Code</th>
                                    <th>Qty</th>
                                    <th>Unit Price</th>
                                    <th>Gross Value(Rs)</th>
                                    <th>Discount, if any(Rs)</th>
                                    <th>Taxable Value(Rs)</th>
                                    
                                        <th>IGST <br/>Rate Amount</th>
                        
                                    <th>Invoice Amount</th>								
                                </tr>
                            
                                    
                                    ${invoiceTableData}
                                <tr>
                                    <td colspan=3>Shipping Charges</td>
                                    <td></td>
                                    <td></td>
                                    <td>7656</td>
                                    <td></td>
                                    <td>123</td>
                                    <td align="center">(9.00%) <br/>2345</td>
                                    <td align="center">(9.00%) <br/>1234</td>
                                </tr>
                                
                                <tr>
                                    <th colspan=5>Total</th>
                                    <th>${totalAmount?.grossValue}</th>
                                    <th>${totalAmount?.totalDiscount}</th>
                                    <th>${totalAmount?.totalTaxableValue}</th>
                                    <th align="center">${totalAmount?.igstAmount}</th>
                                    <th align="center">${totalAmount?.totalFinalPrice}</th>
                                </tr>
                                <tr>
                                    <th colspan=11>Payable Amount (In Words):</th>
                                </tr>
                                <tr>
                                    <th colspan=11>INR ${totalAmount?.totalAmountInWord} Only</th>
                                </tr>
                                <tr>
                                    <th colspan=11>GST Payable On Reverse Charge Basis NO</th>
                                </tr>
                            </tbody>
                        </table>
                    
                    <div class="col-12 g-5">
                        <p class="text-center fw-bold">If you have any questions, get in touch with us<br/>Toll Free: 9289403806 | Email: info@thetechtonic.in</p>
    
                    </div>
                    <div class="col-12">
                        <p class="text-center">Computer generated Invoice and requires no signature</p>
                    </div>
                </div>
            </div>
        </body>
    </html>
        `,
          }}
        />
      )}
    </div>
    
  );
};

export default HtmlRenderer;
