import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Pages from "./Pages/Pages";
import Sidebar from "./components/Sidebar";
import "./App.css";
import Register from "./Pages/Login/Register";
import Splash from "./Pages/Login/Splash";
import Toasty from "./components/Toastify";
import HtmlRenderer from "./Pages/User/HtmlRenderer";
import NewBarcodeScanner from "./Pages/User/NewBarcodeScanner";
import Camera from "./Pages/User/Camera";
import TermsCondition from "./Pages/User/TermsCondition";
import ConfirmationPopup from "./components/ConfirmationPopup";
import WelcomeBanner from "./Pages/User/WelcomeBanner";
// import PdfViewer from "./Pages/User/PdfViewer";
// import BankDetails from "./Pages/User/BankDetails";
// import Formic from "./Pages/User/Formic";

function App() {

  const dashboard = useRef()
  const ToastyTime = 2000;
  const [showToasty, setShowToasty] = useState(false);
  const [toastyMessaga, setToastyMessaga] = useState("");
  const [toastyType, setToastyType] = useState("");
  const [toastifyStatus, setToastifyStatus] = useState("");
  const onToastyData=(e)=> {
    if (e){
      // setToastyType("success")
      setToastifyStatus(e.success)
      setToastyMessaga(e.message)
      setShowToasty(e.isToast);
      setTimeout(() => {
        setShowToasty(false);
      }, ToastyTime+500);
  
    }
  }
  
  

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* The "App" class is applied only to Sidebar and Pages */}
          <Route
            path="/*"
            element={
              <div className="App">
                <div ref={dashboard} className="dashboard">
                  <Sidebar onToastyData={onToastyData} onShowHideClick={(navToggleIcon) => {
                    if(dashboard.current.classList.contains('opened')){
                      dashboard.current.classList.remove('opened')
                      navToggleIcon.current.className = 'bi bi-chevron-double-right'
                      // logo.src = '/assets/images/favicon copy.png'
                    }else{
                      dashboard.current.classList.add('opened')
                      navToggleIcon.current.className = 'bi bi-chevron-double-left'
                    }
                  }} hideSidebar={() => {
                    dashboard.current.classList.remove('opened')
                  }}/>
                  <Pages onToastyData={onToastyData} onShowHideClick={() => {
                    if(dashboard.current.classList.contains('opened')){
                      dashboard.current.classList.remove('opened')
                      // logo.src = '/assets/images/favicon copy.png'
                    }else{
                      dashboard.current.classList.add('opened')
                    }
                  }}/>
                </div>
              </div>
            }
          />
          {/* The Login page outside the "App" class */}
          <Route path="/login" element={<Login onToastyData={onToastyData}  />} />
          <Route path="/register" element={<Register onToastyData={onToastyData} />} />
          <Route path="/direct-login/:uid/:phone" element={<Splash />} />
          <Route path="/notif-login/:type/:id/:uid/:phone" element={<Splash />} />
          <Route path="/orders/invoices/:order_no" element={<HtmlRenderer />} />
          <Route path="/scanner" element={<NewBarcodeScanner />} />
          <Route path="/camera" element={<Camera />} />
          <Route path="/terms&Condition" element={<TermsCondition />} />
          <Route path="/popup" element={<ConfirmationPopup />} />
          <Route path="/" element={<WelcomeBanner />} />
          {/* <Route path="/pdf" element={<PdfViewer />} /> */}
          {/* <Route path="/formic" element={<Formic />} /> */}
        </Routes>
      </BrowserRouter>
      {showToasty && (
    <Toasty showToast={showToasty} message={toastyMessaga} isSuccess={toastifyStatus} time={ToastyTime}/>
    )}
    </div>
  );
}

export default App;
