import React from "react";

function PendingRetailerCard({ data, onApproveRetailer,setOpen, adminSource }) {
  const renderLabelValue = (label, value) => (
    <div className="grid grid-cols-2 mb-1 pt-1.5 px-3 admin-card-border text-gray-900">
      <span className="font-bold w-3/4">{label}:</span>
      <span>{value}</span>
    </div>
  );
  const renderImage = (label, value) => (
    <div className="grid grid-cols-2 mb-1  py-1.5 px-3 admin-card-border text-gray-900">
      <span className="font-bold w-1/2">{label}:</span>
      <img src={value} style={{objectFit: "contain"}} className="h-28 w-56 bg-gray-200" alt="" />
    </div>
  );
//   {
//     "id": 33,
//     "outlet_name": "Meri dukaan",
//     "uid": "LOVE123",
//     "mobile_no": "8556900100",
//     "City": "Gurgaon",
//     "unique_key_id": "4e9dd390-f330-11ee-abea-4b0c97c3c054",
//     "gst": "23AAACH7409R1Z9",
//     "fasai": "BBBRR1221F",
//     "pan_copy": "https://sellerdashboardapi-stage.pep1.in/NaN7cbade31-d45a-43e0-b663-5a31ff650aa9.jpg",
//     "gst_copy": "https://sellerdashboardapi-stage.pep1.in/NaN7e028687-03e3-4e52-8e63-e84bb25ff982.jpg",
//     "holder_name": null,
//     "bank_mobile": null,
//     "account_number": null,
//     "ifsc": null,
//     "bank_cheque": null,
//     "bank_name": null,
//     "branch_name": null,
//     "bank_address": null
// }

return (
  <div
    className="relative flex h-full flex-col rounded-lg bg-white shadow"
  >
    <div className="flex justify-between items-center rounded-t border-b border-gray-600 pt-3 pb-2 pl-7 pr-4">
      <h3 className="text-xl font-medium text-gray-900">Retailer Details</h3>
      <button
      onClick={()=>setOpen(false)}
        aria-label="Close"
        className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
        type="button"
      >
        <svg
          stroke="currentColor"
          fill="none"
          stroke-width="2"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="h-5 w-5"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
    </div>
    <div className="flex-1 overflow-auto p-6">
      <div className="space-y-2 py-5 px-3">
        
            {renderLabelValue("UID", data?.uid)}
            {renderLabelValue("Mobile", data?.mobile_no)}
            {renderLabelValue("City", data?.City)}
            {renderLabelValue("GST", data?.gst)}
            {renderLabelValue("PAN", data?.fasai)}
            {renderImage("GST Copy", data?.gst_copy)}
            {renderImage("PAN Copy", data?.pan_copy)}
            {renderLabelValue("Holder Name", data?.holder_name)}
            {renderLabelValue("Mobile Number(provided for Bank)", data?.bank_mobile)}
            {renderLabelValue("Account Number", data?.account_number)}
            {renderLabelValue("IFSC Code", data?.ifsc)}
            {renderLabelValue("Bank Name", data?.bank_name)}
            {renderLabelValue("Branch Name", data?.branch_name)}
            {renderLabelValue("Bank Address", data?.bank_address)}
            {renderImage("Bank Cheque", data?.bank_cheque)}
      </div>
    </div>
    {adminSource === "super" && (
    <div className="flex items-center space-x-2 rounded-b border-gray-200 px-6 py-2 border-gray-600 border-t">
      <button
      onClick={() => onApproveRetailer(data?.unique_key_id)}
        type="button"
        className="group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none border border-transparent bg-cyan-700 text-white focus:ring-4 focus:ring-cyan-300 enabled:hover:bg-cyan-800 dark:bg-cyan-600 dark:focus:ring-cyan-800  rounded-lg">
        <span
          className="flex items-stretch transition-all duration-200 rounded-md px-4 py-1.5 text-sm"
        >Approve</span>
        </button>
        {/* <button
          type="button"
          className="group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none :ring-cyan-700 border border-gray-200 bg-white text-gray-900 focus:text-cyan-700 focus:ring-4 enabled:hover:bg-gray-100 enabled:hover:text-cyan-700 dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white rounded-lg"
        >
        <span
          className="flex items-stretch transition-all duration-200 rounded-md px-4 py-2 text-sm"
        >Close</span>
      </button> */}
    </div>
    )}
  </div>


)
}

export default PendingRetailerCard;
