import { axiosCall } from "./common";
import { AUTH_URL, ORDER_URL, POST_PINCODE_API } from "../helper";

export const login = async (userData) => {
  try {
    const response = await axiosCall(AUTH_URL + "/login", "post", userData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const directLogin = async (userData) => {
  try {
    const response = await axiosCall(
      AUTH_URL + "/direct-login",
      "post",
      userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const registerNewSeller = async (userData) => {
  try {
    const response = await axiosCall(AUTH_URL + "/register", "post", userData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const contactUsSubmit = async (userData) => {
  try {
    const response = await axiosCall(
      AUTH_URL + "/contact-us",
      "post",
      userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const pincodeApi = async (data) => {
  return new Promise(async (resolve) => {
    try {
      // const headers = {Authorization: localStorage.getItem('token')};
      const response = await axiosCall(POST_PINCODE_API + data, "get");
      resolve(response);
    } catch (error) {
      resolve(false);
    }
  });
};

export const registerBankDetails = async (data) => {

  try {
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    const response = await axiosCall(ORDER_URL + "/settings/bank_details/add", "post", data, headers);
    return response;
  } catch (error) {
    throw error;
  }
};
export const editBankDetails = async (data) => {

  try {
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    const response = await axiosCall(ORDER_URL + "/settings/bank_details/update", "post", data, headers);
    return response;
  } catch (error) {
    throw error;
  }
};